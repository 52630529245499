<template>
  <v-container fluid class="hero">
    <v-row>
      <v-card-text>
        <v-form ref="userForm" @submit.prevent="updateCurrentPayment()">
          <v-row justify="center">
            <v-col cols="12" md="4">
              <v-text-field
                label="Weekday Rate"
                v-model="payment.weekdayRate"
                outlined
                disabled
                prefix="R"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Weekend Rate"
                v-model="payment.weekendRate"
                outlined
                disabled
                prefix="R"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Holiday Rate"
                v-model="payment.holidayRate"
                outlined
                disabled
                prefix="R"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                label="Week days actually worked"
                v-model="paymentDataModified.weekDaysWorked"
                outlined
                @change="onChange()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Weekend days"
                v-model="paymentDataModified.weekendDaysWorked"
                outlined
                @change="onChange()"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Public Holiday"
                v-model="paymentDataModified.publicHolidayWorked"
                outlined
                @change="onChange()"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" md="4">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="paymentDataModified.payDate"
                    label="Projected pay date"
                    append-icon="mdi-calendar"
                    readonly
                    outlined
                    type="date"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="paymentDataModified.payDate"
                  outlined
                  :min="paymentDataModified.payDate"
                  @change="menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Projected Pay"
                v-model="payment.amount"
                outlined
                disabled
                prefix="R"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col class="text-center">
              <v-btn type="submit" color="success" class="text-align-right">
                Update
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-row>
  </v-container>
</template>
<script>
import { gql } from "apollo-boost";
import UserCreate from "../../components/Base/UserCreate.vue";
import Holidays from "date-holidays";
export default {
  components: { UserCreate },
  name: "Home",
  mounted() {},
  data() {
    console.log("this.$route.query.test", this.$route.query);
    console.log("this.$route.query.paymentId", this.$route.query.paymentId);
    return {
      payment: {},
      menu: false,
      paymentDataModified: {
        weekDaysWorked: 0,
        publicHolidayWorked: 0,
        weekendDaysWorked: 0,
        payDate: null,
      },
      paymentId: this.$route.query.paymentId,
      isEdit: true,
      hd: new Holidays("ZA"),
    };
  },
  ////////////////////////////////
  apollo: {
    payment: {
      query: gql`
        query Payment($paymentId: ID!) {
          payment(id: $paymentId) {
            amount
            createdAt
            days
            id
            employee
            employeeName
            employer
            employerName
            holidayRate
            period
            processBy
            publicHoliday
            rate
            status
            updatedAt
            weekdayRate
            weekendRate
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        return {
          paymentId: this.paymentId,
        };
      },
      result(response) {
        console.log("response from payment by param ID", response.data);
        if (response.data.payment.id != "" || null) {
          this.payment = this.payment;
          this.isEdit = true;
        } else {
          this.isEdit = false;
        }
        for (const key in this.payment) {
          if (key == "days") {
            this.payment[key].forEach((pay) => {
              pay = new Date(pay);
              if (
                this.hd.isHoliday(pay) &&
                (pay.getDay() === 6 || pay.getDay() === 0)
              ) {
                this.paymentDataModified.publicHolidayWorked += 1;
              } else if (pay.getDay() === 6 || pay.getDay() === 0) {
                this.paymentDataModified.weekendDaysWorked += 1;
              } else {
                this.paymentDataModified.weekDaysWorked += 1;
              }
            });
          }
          if (key == "processBy") {
            const newDate = new Date(this.payment[key]);
            const newDay = newDate.getDate().toString().padStart(2, "0");
            const newYear = newDate.getFullYear();
            const newMonth = (newDate.getMonth() + 1)
              .toString()
              .padStart(2, "0");
            this.paymentDataModified.payDate = `${newYear}-${newMonth}-${newDay}`;
          }
        }
      },
    },
  },
  //////////////////////////////////
  methods: {
    onChange() {
      console.log("Heeeeeeee");
      this.payment.amount =
        parseFloat(this.paymentDataModified.publicHolidayWorked) *
          parseFloat(this.payment.holidayRate) +
        parseFloat(this.paymentDataModified.weekDaysWorked) *
          parseFloat(this.payment.weekdayRate) +
        parseFloat(this.paymentDataModified.weekendDaysWorked) *
          parseFloat(this.payment.weekendRate);
    },
    async updateCurrentPayment() {
      const self = this;
      const response = await self.$apollo.mutate({
        mutation: gql`
          mutation updatePayment($payment: PaymentUpdateInput!) {
            updatePayment(payment: $payment) {
              id
            }
          }
        `,
        variables: {
          payment: {
            status: "scheduled",
            id: this.paymentId,
            processBy: new Date(this.paymentDataModified.payDate),
            employer: this.payment.employer,
            employee: this.payment.employee,
            status: this.payment.status,
            amount: this.payment.amount,
          },
        },
      });
      this.dialog = false;
      if (response?.data?.updatePayment?.id) {
        this.$swal(
          "Success!",
          "Payment line Date was updated successfully!",
          "success"
        ).then(() => {
          this.$router.push("/employer/landing");
        });
      } else {
        this.$swal("Error!", "Something went wrong, please try again later");
      }
      this.isLoading = false;
    },
  },
  computed: {},
};
</script>
<style scoped>
.herso {
  background: url("../../assets/landing.png") no-repeat center center fixed !important;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}

/* .v-input__slot {
  min-height: 200px !important;
  display: flex !important;
  align-items: center !important;
} */
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}

.v-sheet.v-card {
  background: #6dd984;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.theme--light.v-list {
  background: #6dd984;
}

.card-header {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 20px;
}

.select-card {
  padding: 0px 30px;
  min-height: 40px;
  position: relative;
}

.top-sect .v-btn {
  width: calc(100% - 67px);
  min-height: 70px;
}

.top-sect .v-btn__content {
  color: #eeecf1 !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  letter-spacing: 0.75px;
}

.top-sect .v-card__actions {
  position: absolute;
  right: 10px;
  top: 10px;
}

.top-sect .v-icon.v-icon {
  color: #ffffff;
}

.select-list {
  margin-top: 10px;
  background: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
}

.v-application .select-list .primary--text {
  color: #7d72f5 !important;
  caret-color: #7d72f5 !important;
}

.v-sheet.v-card .theme--light.v-divider {
  border-color: #fff;
}

.forCale {
  background-color: white;
}

.forCale {
  padding: 10px;
  overflow: hidden;
}

.forCale .names {
  background: #a5a6f6;
  border-radius: 10px;
}

.v-sheet.v-card .v-list-item__content {
  padding: 10px 10px;
  display: block;
}

.v-sheet.v-card .v-avatar--tile {
  height: 60px !important;
}

.v-sheet.v-card .v-list-item__content .v-list-item__title.text-h5 {
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: #3f3d56;
}

.v-sheet.v-card .v-list-item__content .v-list-item__subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #3f3d56;
}

.v-sheet.v-card v-item-subtitle h1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 75px;
  text-align: center;
  text-decoration-line: underline;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.v-application .mb-10 {
  margin-bottom: 20px !important;
}

.ico-point .mdi:before,
.ico-point .mdi-set {
  font-size: 50px;
}

.v-sheet.v-card .v-expansion-panel {
  background-color: #6dd984;
}

.v-sheet.v-card .v-expansion-panel i.v-icon {
  color: #fff !important;
}

.v-expansion-panel-header .col-10 h3 {
  font-weight: 600;
  font-size: 17px;
  line-height: 16px;
  color: #3f3d56;
}

.v-expansion-panel-header .col-10 p {
  color: #3f3d56;
}

.custom.v-combobox > .v-input__control > .v-input__slot:before {
  border-style: none !important;
}

.custom.v-combobox > .v-input__control > .v-input__slot:after {
  border-style: none !important;
}
</style>
